import { Injectable } from '@angular/core';
import { FindDataClass } from '../find.data.class';
import { FindResponseInterface } from '../../../interfaces/response/find.response.interface';
import { PageInterface } from '../../../interfaces/model/page.interface';
import { PageService } from '../../page.service';

@Injectable()
export class PageDataService extends FindDataClass<PageInterface> {

  constructor(private pageService: PageService) {
    super();
  }

  public findCall(...params: [string]): Promise<FindResponseInterface<PageInterface>> {
    return this.pageService.findById(...params);
  }

}
