import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FindResponseInterface } from '../interfaces/response/find.response.interface';
import { PageInterface } from '../interfaces/model/page.interface';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private api: ApiService) {
    // Noop
  }

  public findById(id: string): Promise<FindResponseInterface<PageInterface>> {
    return this.api.get(`/pages/${id}`);
  }

}
